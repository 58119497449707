<script>
import { mapActions, mapGetters } from "vuex";
import Pagination from "@/components/Pagination";
import { dateTransform } from "@/utils/utils.js";
import editor from './editor';

export default {
  props: ['pageNum'],
  data() {
    return {
      tableColumns: [
        {
          label: "apk名称",
          prop: "file_real_name",
          width: 100
        },
        {
          label: "apk路径",
          prop: "file_down_path",
          width: 180
        },
        {
          label: "apk版本",
          prop: "name",
          width: 100
        },
        {
          label: "创建人",
          prop: "create_by",
        },
        {
          label: "版本说明",
          prop: "remark",
          width: 120
        },
        {
          label: "白名单状态",
          prop: "white_list_status",
          width: 90
        },
        {
          label: "白名单",
          prop: "white_list"
        },
        {
          label: "状态",
          prop: "status",
          width: 60
        },
        {
          label: "创建时间",
          prop: "create_time",
          width: 130
        },
        {
          label: "操作",
          prop: "operations",
          width: "160px"
        },
      ],
      formatMap: {
        white_list_status: {
          'true': '启用',
          'false': '停用'
        },
        status: {
          'true': '启用',
          'false': '停用'
        }
      }
    };
  },
  computed: {
    ...mapGetters(["apkList", "apkListTotal"]),
    formatTime() {
      return (val) => {
        return dateTransform(val) || "";
      };
    },
    tableHeight() {
      let h = document.body.clientHeight - 60 - 20 - 20 - 47 - 32 - 15;
      h = h - 28 - 15; 
      return h;
    }
  },
  methods: {
    ...mapActions(['deleteApkData']),
    handleSelectionChange(val) {
      this.$emit("selectChange", val);
    },
    getTableData(params) {
      this.$emit("getDatas", params);
    },
    getFormatName(key, val) {
      return this.formatMap[key][val] || '';
    },
    handleEdit(row) {
      this.$router.push('/apk/edit?id=' + row.id);
    },
    editWhiteList(row) {
      this.$refs.editorRef.show({
        id: row.id,
        white_list: row.white_list
      });
    },
    async deleteData(row) {
      try {
        this.$changeLoadingState(true);
        await this.deleteApkData({ids: [row.id]});
        this.$changeLoadingState(false);
        this.getTableData();
      } catch (error) {
        console.log(error);
        this.$changeLoadingState(false);
      }
    },
    editorConfirm() {
      this.$emit("getDatas");
    }
  },
  components: {
    Pagination,
    editor
  },
};
</script>
<template>
  <div>
    <el-table
      :data="apkList"
      style="width: 100%"
      class="projectsTable"
      border
      :height="tableHeight"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ formatTime(scope.row[c.prop]) }}
          </div>
          <div v-else-if="!!formatMap[c.prop]">
            <span :style="{color: scope.row[c.prop] === 'true' ? '#67C23A' : '#F56C6C'}">{{ getFormatName(c.prop, scope.row[c.prop]) }}</span>
          </div>
          <div v-else-if="c.prop === 'file_down_path'" class="table-ellipsis-text" :title="scope.row[c.prop]">
            <a target="_blank" :href="scope.row[c.prop]">{{scope.row[c.prop]}}</a>
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-button
              size="mini"
              type="text"
              @click="handleEdit(scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              type="text"
              :disabled="scope.row.white_list_status !== 'true'"
              @click="editWhiteList(scope.row)"
            >
              白名单设置
            </el-button>
            <el-popconfirm
              title="确定要删除当前数据吗？"
              @confirm="deleteData(scope.row)"
            >
              <el-button
                slot="reference"
                type="text"
                size="mini"
                class="delete-text-btn"
                style="margin-left: 10px;"
              >
                删除
              </el-button>
            </el-popconfirm>
          </div>
          <span v-else class="table-ellipsis-text" :title="scope.row[c.prop]">{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="apkListTotal > 0"
      :handleSizeChange="
        (v) => {
          this.getTableData({ page_size: v });
        }
      "
      :handleCurrentPageChange="
        (v) => {
          this.getTableData({ page_num: v });
        }
      "
      :total="apkListTotal"
      :pageNum="pageNum"
    />
    <editor ref="editorRef" @confirm="editorConfirm"></editor>
  </div>
</template>